@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

.reviewCard {
  position: relative;
  box-sizing: border-box;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-bottom-2);
  height: 256px;

  @include until-phone {
    height: 248px;
  }

  .background {
    background-color: var(--primary-variant-1);
    border-radius: var(--border-radius-md);
    height: 100%;
  }

  .apostrophe {
    position: absolute;
    right: 20px;
    top: 16px;

    @include until-phone {
      right: 16px;
    }
  }

  .body {
    position: relative;
    z-index: 1;
    padding: var(--space-6);

    .comment {
      --lh: 24px;
      margin-top: var(--space-3);
      position: relative;
      max-height: calc(3 * var(--lh));
      overflow: hidden;
      color: var(--text-color-grey-1);
      @include label-md-bold;
      line-height: var(--lh);

      &.expanded {
        max-height: inherit;
      }

      &.ellipsize {
        @include overflowedText(3);
      }
    }

    .separator {
      position: relative;
      display: inline-block;
      width: var(--space-4);
      height: 10px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: var(--neutral-1);
      }
    }

    button {
      justify-content: left;
      margin-top: var(--space-1);
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 24px;
    border-top: 1px solid var(--neutral-5);
    background: var(--neutral-6);
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 24px;
      top: -8px;
      width: 15px;
      height: 15px;
      background: var(--white);
      border-right: 1px solid var(--neutral-5);
      border-bottom: 1px solid var(--neutral-5);
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .nameAndType {
      color: var(--text-color-grey-1);
      padding-right: var(--space-4);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: var(--space-1);

      @include label-sm;
      @include until-phone {
        @include text-sm;
      }

      .separator {
        margin: 0 var(--space-1);
        position: relative;
        top: -0.25em;
      }
    }

    .dateOrProductName {
      color: var(--text-color-grey-1);

      @include overflowedText(1);
      @include label-sm-bold;
      @include until-phone {
        @include text-sm-bold;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: var(--space-1);
    }

    .logoContainer {
      display: flex;
      align-items: flex-end;
      gap: var(--space-2);

      span {
        display: inline-block;
        margin-bottom: var(--space-1);
      }
    }
  }
}
