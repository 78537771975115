@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

.box {
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid var(--text-color-grey-5);
  padding: var(--space-3) 0 var(--space-4);

  .background {
    background-color: var(--primary-variant-1);
    border-radius: var(--border-radius-md);
    height: 100%;
  }

  .reviewHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-1-half);
  }

  .date {
    @include text-sm;

    color: var(--text-color-grey-3);
  }

  .body {
    position: relative;
    z-index: 1;
    padding: 0;

    .comment {
      --lh: 24px;
      @include label-md;

      position: relative;
      max-height: calc(3 * var(--lh));
      overflow: hidden;
      color: var(--text-color-grey-1);
      line-height: var(--lh);
      margin-top: var(--space-3);
      font-weight: var(--font-weight-regular);

      &.expanded {
        max-height: inherit;
      }

      &.ellipsize {
        @include overflowedText(3);
      }
    }

    button {
      justify-content: left;
      margin-top: var(--space-1);
      // font-weight: normal;
    }
  }

  .nameAndType {
    color: var(--text-color-grey-1);
    padding-right: var(--space-4);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: var(--space-2);

    position: relative;

    .separator {
      margin: 0 var(--space-1);
      position: relative;
      top: -0.25em;
    }

    .travellerType {
      color: var(--text-color-grey-3);
    }

    @include text-sm;
  }

  .footer {
    position: relative;
    width: 100%;

    .dateOrProductName {
      color: var(--text-color-grey-1);
      position: relative;

      @include overflowedText(1);
      @include label-sm-bold;
      @include until-phone {
        @include text-sm-bold;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: var(--space-1);
    }

    .logoContainer {
      display: flex;
      align-items: flex-end;
      gap: var(--space-2);

      span {
        display: inline-block;
        margin-bottom: var(--space-1);
      }
    }
  }

  &.pagination {
    padding: var(--space-4) 0;
    border-color: var(--text-color-grey-4);

    .body {
      .comment {
        margin-top: var(--space-2);
        @include label-md;
      }
    }

    .nameAndType {
      margin-top: var(--space-4);
      margin-bottom: var(--space-1);

      @include label-sm;
      @include until-phone {
        @include text-sm;
      }
    }
  }
}
