@import 'scss/typography';
@import 'scss/mixins';

.notification {
  --notification-height: 60px;

  @include until-phone {
    --notification-height: 136px;
    flex-direction: column;
  }

  &.position-top {
    position: relative;
    top: 0;
    bottom: unset;
  }

  &.position-bottom {
    position: fixed;
    bottom: 0;
    top: unset;
  }

  z-index: 1001;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--space-4);
  background-color: var(--secondary-variant-2);
  width: 100vw; // to make sure custom scroll width is not consuming space from notification
  @include fadeIn(0.5s);

  &Text > div {
    @include from-phone {
      display: inline;
    }
  }

  &Header {
    @include label-md-ex-bold;
    margin-bottom: var(--space-2);
    color: var(--text-color);

    @include from-tablet {
      margin-right: var(--space-2);
      @include header-5;
    }
  }

  &Inner {
    @include maxContentWidth;
    @include label-md;
    color: var(--text-color-grey-1);
    padding: 0;
    position: relative;
    width: 100%;
    max-width: var(--container-max-width);
    padding-left: var(--page-side-space);
    padding-right: var(--page-side-space);
    text-align: center;

    &Content {
      gap: var(--space-2);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 var(--space-2);
      position: relative;

      @include until-phone {
        flex-direction: column;
      }

      a {
        margin-left: var(--space-2);
        text-align: center;

        &:hover {
          text-decoration: underline;
        }
        @include until-phone {
          @include header-5-bold;
        }
      }

      .icon {
        display: flex;
      }
    }

    &Message {
      display: inline-block;
      padding-right: var(--space-8);
    }
  }

  .closeCta {
    color: var(--text-color-grey-1);

    @include from-tablet {
      order: unset;
      position: absolute;
      margin: 0 auto;
      right: var(--space-6);
      cursor: pointer;
    }

    @include until-phone {
      order: -1;
      align-self: flex-end;
    }

    svg {
      display: block;
    }
  }
  .buttonCtaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonCta {
    flex-shrink: 0;

    @include from-tablet {
      margin-left: var(--space-6);
    }
  }

  .linkTnC {
    @include link(var(--text-color-grey-1), var(--text-color-tertiary));
    @include text-sm;
    margin-left: var(--space-2);
    text-decoration: underline;

    @include until-phone {
      @include text-xs;
      font-weight: var(--font-weight-regular);
    }
  }

  .descriptionText {
    display: inline;
    @include until-phone {
      @include label-sm;
    }
  }
}
