@import 'scss/media-queries';

.dynamicComponents {
  :global(#hero) {
    margin-top: 0;
  }

  > * {
    margin-top: var(--space-16);

    @include until-phone {
      margin-top: var(--space-12);
    }
  }

  // bg variant doesnt need to have margin top ONLY if it is the direct sibling of first fold
  :global(#hero + .marginless-hero-sibling) {
    margin-top: 0 !important;
  }

  > *:nth-child(2) {
    @include until-phone {
      // first child after the first fold should have less space on top compared to others on mobile
      margin-top: var(--space-8);
    }
  }
}
