@import 'scss/mixins';
@import 'scss/typography';

.title {
  @include header-5-bold;
  margin-bottom: var(--space-6);

  @include until-phone {
    margin-top: 0;
  }
}

.ol {
  list-style: none;
  counter-reset: steps;

  > li {
    margin-bottom: var(--space-4);
    counter-increment: steps;
    @include label-md-bold;

    &::before {
      content: counter(steps);
      margin-right: var(--space-2);
      background: var(--secondary-variant-2);
      border-radius: 50%;
      display: inline-grid;
      place-items: center;
      color: var(--text-color-grey-1);
      @include size(28px);
      @include label-md-bold;
    }

    ol {
      margin-left: calc(var(--space-8) + var(--space-1));
    }

    ol,
    ol li {
      list-style: none;
      counter-increment: none;
      font-weight: var(--font-weight-regular);
    }
  }

  .regularText {
    font-weight: var(--font-weight-regular) !important;
  }
}
