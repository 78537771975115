@import 'scss/typography';
@import 'scss/mixins';

// container which has background should come with proper padding
// this should not be decided by parent
.bgGrey {
  padding-top: var(--space-16);
  padding-bottom: var(--space-16);
  position: relative;
  isolation: isolate;

  @include until-phone {
    @include padding-y(var(--space-8));
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: calc(-1 * (100vw - 100%) / 2);
    background: var(--neutral-5);
    z-index: -1;
  }

  .sectionHeading {
    background: var(--neutral-5);
  }
}

.sectionHeading {
  display: flex;
  flex-direction: column;
  color: var(--text-color);

  .pills {
    display: flex;
    margin-top: var(--space-4);
    overflow-x: auto;
    max-width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0; /* Remove scrollbar space */
    }

    .pill {
      margin-bottom: 0 !important;
    }
  }

  .pillDestinationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-4);

    .pillContainer {
      overflow-x: auto;
      flex: 1;

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        max-width: none;
        overflow: visible;

        > div > div {
          overflow: visible;
        }
      }
    }

    > button {
      white-space: nowrap;
    }
  }
}

.headingText {
  display: flex;
  flex-direction: column;
  color: var(--text-color-grey-1);

  h3 {
    @include header-3;
  }
}

.subTitle {
  @include label-md;
  margin-top: var(--space-2);
  font-weight: var(--font-weight-regular);

  @include until-phone {
    @include text-sm;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.activityCount {
  @include label-md;
  margin-bottom: var(--space-2);
  font-weight: var(--font-weight-regular);

  @include until-phone {
    @include text-sm;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.products {
  margin-top: var(--space-6);

  &._horizontal {
    grid-template-columns: 1fr auto 1fr;
    row-gap: var(--space-8);
    grid-auto-rows: 210px;

    & > *:nth-child(even) {
      grid-column-start: 3;
    }

    @include until-tablet {
      row-gap: var(--space-4);
      grid-template-columns: unset;
      grid-auto-rows: auto;

      & > *:nth-child(even) {
        grid-column-start: unset;
      }
    }
  }

  &.broaderGap {
    grid-gap: var(--space-6);
  }
}

.seeMore {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-4);

  @include from-phone {
    align-items: center;
    margin-top: var(--space-12);
  }

  .loadMore {
    align-self: center;
  }

  .seeMoreText {
    text-align: center;
    @include label-md;
    color: var(--text-color-grey-2);
    margin-bottom: var(--space-8);

    @include until-phone {
      @include label-sm;
    }
  }

  .seeAllCta,
  .showAllCta {
    margin-bottom: var(--space-0);
  }
}

.seeAllDestinationBtn {
  white-space: nowrap;
  margin-left: var(--space-2);
}
