@import 'scss/typography';

.exclusiveBenefits {
  position: relative;

  .title {
    @include header-3;
    margin-bottom: var(--space-4);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-6);

    &.twoCol {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include until-tablet {
    padding: 0;

    .title {
      padding: 0 var(--space-4);
      margin-bottom: var(--space-2);
    }

    // Card slider header row
    > div > div:first-child {
      margin-bottom: 0;
    }
  }
}
