@import 'scss/mixins';
@import 'scss/typography';

.container {
  width: 100%;
  text-align: center;
  position: relative;
  background-color: var(--white);
  margin-top: calc(var(--header-height) * -1) !important;

  @include until-phone {
    height: auto;
  }

  .heroCover {
    height: calc(var(--header-height) + 344px);
    position: relative;

    @include until-phone {
      height: auto;
      aspect-ratio: 1 / 1;
    }
  }

  .searchInputWayPoint {
    position: absolute;
    width: 1px;
    left: 0;
    opacity: 0;

    @include until-phone {
      top: var(--space-16);
    }
  }

  .scrollStartWayPoint {
    position: absolute;
    top: calc(var(--notification-height) * -1);
  }

  .heroImage {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      opacity: 0;
      transition: opacity 1250ms ease-out;
      position: absolute;
      width: 100%;
      min-height: 100%;
      top: 0;

      &.active {
        opacity: 1;
      }
    }

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 78.38%, rgba(0, 0, 0, 0.75) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 47.01%, rgba(0, 0, 0, 0.75) 100%);

      &.hideBottomGradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 78.38%, rgba(0, 0, 0, 0.75) 100%);
      }
    }
  }

  .body {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 1;
    text-align: left;
    padding-top: var(--space-18);
    padding-bottom: var(--space-8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include until-phone {
      padding: 0 var(--space-5);
      padding-top: var(--space-19);
      padding-bottom: var(--space-6);
    }

    .searchInputWrapper {
      position: relative;
      z-index: 2;
      width: Min(550px, calc(100% - 32px));
      display: grid;
      grid-template-columns: auto max-content;
      @include label-md;

      &:focus-within {
        &:after {
          content: '';
          position: fixed;
          z-index: -1;
          inset: 0;
          background-color: rgba(0, 0, 0, 0.25);
        }
      }

      @include until-phone {
        display: block;
        width: 100%;
      }
    }

    .headerSection {
      display: flex;
      flex-direction: column;
      gap: var(--space-8);

      .header {
        text-align: center;
        color: var(--white);
        white-space: pre-wrap;
        text-shadow: 1px 1px 16px rgba(0, 0, 0, 0.75);
        @include header-1;

        span {
          color: var(--primary);
          font-family: var(--font-family-caveat);
          font-weight: normal;
          font-size: 72px;
        }
      }

      @include until-phone {
        gap: var(--space-4);
        width: 100%;

        .header {
          @include header-2;

          span {
            font-size: 52px;
            line-height: 40px;
          }
        }
      }
    }

    .scrollIndicator {
      position: absolute;
      bottom: 8px;
      margin: 0 auto;
      animation-name: bounce;
      animation-timing-function: linear;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      cursor: pointer;
      left: 50%;
      transform: translateX(-50%);
    }

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(8px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .categoriesContainer {
    display: flex;
    justify-content: center;
    margin-top: var(--space-8);
    padding: 0 79px;

    @include until-desktop {
      padding: 0 var(--space-16);
    }

    @include until-phone {
      padding: 0;
    }
  }
}
