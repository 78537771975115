@import 'scss/typography';

.body {
  height: 100vh;
  max-height: 100vh;
  background: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;

  .header {
    background: var(--header-nav-bg);
    padding: var(--space-3) var(--space-4);
    display: grid;
    gap: var(--space-2);
    grid-template-columns: 1fr auto;
    grid-auto-rows: auto;
    min-width: 100%;
    @include header-3;
    border-bottom: 1px solid var(--neutral-5);

    > button {
      display: flex;
      align-items: center;
      color: var(--white);
    }

    &.drawer {
      background: var(--primary-variant-1);
      grid-template-columns: auto 1fr;

      > button {
        order: -1;
        color: var(--text-color);
      }
    }
  }
}
