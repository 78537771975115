@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/spacing';

.box {
  position: relative;
  padding: var(--space-12) 0;
  background-color: var(--neutral-6);
  width: 100%;

  @include until-tablet {
    padding: var(--space-6) 0;
  }

  .kfLink {
    margin: 0 0 0 var(--space-1);

    @include until-tablet {
      display: block;
      margin: var(--space-1) 0 0;
    }
  }

  .container {
    @include row;
    position: relative;

    .illustration {
      position: absolute;
      right: 0;
      top: -86px;
      width: 130px;
      height: 128px;

      @include until-tablet {
        width: 101px;
        height: 105px;
        top: -48px;
      }
    }
  }

  .inline {
    @include col-12;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-2);
    margin-top: var(--space-6);

    @include from-tablet {
      .three-col {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @include until-tablet {
      grid-template-columns: 1fr;
      margin-top: var(--space-4);
      gap: var(--space-4);
    }

    .prop {
      background-color: var(--primary-variant-1);
      border-radius: var(--border-radius-md);
      padding: var(--space-4);
      display: grid;
      grid-template-columns: 44px 1fr;
    }
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--space-6);
  }
}
