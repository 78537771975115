@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.container {
  min-height: unset;
  padding-top: 0;
}

.box {
  position: relative;
  border-radius: var(--border-radius-md);
  background-color: var(--primary-variant-2);
  padding: var(--space-6);
  overflow: hidden;

  @mixin mobile-variant {
    padding: var(--space-6) var(--space-4);
    border-radius: unset;

    .body {
      grid-template-columns: 1fr;
      gap: var(--space-4);

      .text {
        margin: 0;
      }

      .link {
        @include until-phone {
          position: relative;
          right: unset;
          bottom: unset;
          width: auto;

          a {
            margin-left: 0;
          }
        }
      }
    }
    .promoCodeButton {
      width: fit-content;
    }

    .background {
      bottom: 0;
      top: unset;
      right: -92px;
    }
  }

  .body {
    position: relative;
    display: grid;
    grid-template-columns: minmax(277px, 446px) auto;
    gap: var(--space-9);
    justify-content: center;
    align-items: center;

    @include until-tablet {
      gap: var(--space-6);
    }

    .text {
      color: var(--primary-variant-1);

      p {
        @include header-3-bold;
      }
    }

    .link {
      a {
        @include text-sm;

        color: var(--primary-variant-1);
        white-space: normal;
        text-decoration: underline;
        font-weight: normal;
        margin-left: var(--space-2);
      }
    }
  }

  .promoCodeButton {
    display: flex;
  }

  &.leftAlign {
    .body {
      justify-content: unset;
    }

    .promoCodeButton {
      width: fit-content;
    }
  }

  .background {
    position: absolute;
    top: 0;
    right: 52px;

    @include until-tablet {
      bottom: 0;
      top: unset;
      right: -92px;
    }
  }

  @include until-phone {
    @include mobile-variant;
  }

  &.mini-variant {
    @include mobile-variant;

    .body {
      .text {
        p {
          @include header-5-bold;
        }
      }
    }
  }

  .footer {
    @include until-phone {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: var(--space-4);
      align-items: end;
    }
  }
}
