@import 'scss/mixins';
@import 'scss/typography';

.howItWorks {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--primary-variant-2);
  cursor: pointer;

  &.variant-pdp {
    justify-content: center;

    padding: var(--space-2);
    background-color: var(--secondary-variant-2);

    @include until-phone {
      border-radius: 0;
    }
  }

  &.variant-pdp,
  &.variant-booking {
    &.rounded-top {
      border-top-left-radius: var(--border-radius-md);
      border-top-right-radius: var(--border-radius-md);
    }

    &.square {
      border-radius: 0;
    }
  }

  &.variant-booking,
  &.text-right {
    justify-content: right;
  }

  &.text-left {
    justify-content: left;
  }

  .label {
    @include text-xs;
    font-weight: var(--font-weight-semi-bold);

    font-family: var(--font-family-montserrat);
    letter-spacing: 0.8px;
    line-height: 16px;
    text-transform: uppercase;
  }
}
