@import 'scss/mixins';
@import 'scss/media-queries';

.clipboard {
  position: relative;

  &:not(.copied)::before {
    display: none;
  }

  &.copied::before {
    opacity: 1;
    @include fadeIn(0.3s);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: attr(data-copied-text);
    background: rgba(46, 46, 46, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    padding: 10px 15px;
    opacity: 0;
    color: #fff;
    z-index: 1;

    @include until-phone {
      top: 25%;
      // why 25 - in mobile this content overlaps with t&c text if keep 50% hence makes it non-clickable
    }
  }
}
