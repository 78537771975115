@import 'scss/typography';
@import 'scss/media-queries';

.expandCta {
  justify-content: start;
  margin-top: var(--space-4);

  &.normalWeight {
    font-weight: var(--font-weight-regular);
  }

  @include until-phone {
    justify-content: center;
  }
}
