@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.container {
  min-height: unset;
  padding-top: 0;
}

.box {
  position: relative;
  border-radius: var(--border-radius-md);
  background-color: var(--neutral-7);
  padding: var(--space-6);
  overflow: hidden;

  @include until-phone {
    padding: var(--space-5) var(--space-4);
    border-radius: unset;
  }

  .body {
    --svg-width: 98px;

    position: relative;
    display: grid;
    grid-template-columns: var(--svg-width) minmax(200px, 540px) auto;
    gap: var(--space-6);
    justify-content: center;
    align-items: center;

    font-family: var(--font-family-montserrat);

    @include until-phone {
      --svg-width: 108px;

      grid-template-columns: var(--svg-width) auto;
      grid-template-rows: 1fr auto;
      gap: var(--space-2);
      align-items: start;
    }

    .img {
      position: absolute;
      display: inline;
      width: var(--svg-width);
      height: 140px;
      top: 0%;

      @include until-phone {
        height: 155px;
      }
    }

    .text {
      color: var(--text-color-grey-1);

      p {
        @include header-4;
        color: var(--text-color);
      }

      @include until-phone {
        margin: 0 var(--space-1);

        p {
          @include header-5;
        }
      }

      .link {
        white-space: normal;
        text-decoration: underline;
        color: var(--text-color-grey-2);
        @include text-sm;
        font-family: var(--font-family-montserrat);

        @include until-phone {
          display: flex;
          width: 100%;
          margin-top: var(--space-1);
          @include text-xs;
        }
      }
    }
  }

  .promoCodeButton {
    display: flex;
    align-items: center;
    border: 1px solid var(--text-color);
    border-radius: var(--border-radius-sm);
    padding: var(--space-2) var(--space-6);
    text-transform: uppercase;
    cursor: pointer;
    color: var(--text-color);

    @include until-phone {
      padding: var(--space-1) var(--space-3);
      width: fit-content;
      @include label-sm;
      grid-column: 2;
    }

    div {
      @include label-md;
      @include until-phone {
        @include label-sm;
      }
    }
  }
}
