@import 'scss/typography';
@import 'scss/spacing';
@import 'scss/mixins';

.container {
  width: 100%;
  position: relative !important;
  padding-top: 0 !important;
  min-height: unset !important;

  &::after {
    $sideSpace: calc(var(--page-side-space) * 2);
    max-width: calc(var(--container-max-width) - #{$sideSpace});
    margin-left: auto;
    margin-right: auto;
  }

  @include until-phone {
    padding-left: var(--space-4);
    padding-right: var(--space-4);

    > div:first-child {
      width: 100%;
      max-width: unset;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-6);

    @include until-phone {
      margin-bottom: var(--space-4);
    }

    span {
      @include header-3;
      color: var(--text-color-grey-1);
      font-weight: var(--font-weight-extra-bold);
    }
  }

  .row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    column-gap: var(--space-4);
    padding-left: var(--space-2);
    padding-right: var(--space-2);

    @include until-tablet {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--space-4);
    }
    @include until-phone {
      grid-template-columns: repeat(2, 1fr);
    }

    .card {
      border-radius: var(--border-radius-md);
      position: relative;
      /* Shadow, var(--space-1-half) bottom */
      filter: var(--shadow-bottom-2);

      @include from-tablet {
        height: 248px;
        width: 100%;
        max-width: 392px;
      }

      &:focus-visible,
      &:focus {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          box-shadow: var(--black-interaction-shadow-keyboard-focus);
          border-radius: inherit;
        }
      }

      &.large .destination {
        @include header-5;
        @include until-phone {
          @include header-4;
        }
      }
      &.medium .destination {
        @include header-6;
        @include until-phone {
          @include header-5;
        }
      }
      &.small .destination {
        @include label-sm;
      }

      will-change: transform, filter;
      transition: transform 0.2s, filter 0.2s;

      &:hover {
        transform: translateY(-4px);
        filter: drop-shadow(var(--shadow-bottom-4));
      }

      img {
        border-radius: var(--border-radius-md);
        object-fit: cover;
      }

      .placeholder {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65.91%, #000000 100%);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }

      .destination {
        @include header-4;
        font-weight: var(--font-weight-extra-bold);
        color: var(--text-white);
        position: absolute;
        bottom: 8px;
        @include padding-x(var(--space-3));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }
  }

  .cta {
    margin-top: var(--space-6);

    @include until-phone {
      margin-top: var(--space-4);
    }
  }
}
