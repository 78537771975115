@import 'scss/mixins';
@import 'scss/typography';

.background {
  background: linear-gradient(180deg, var(--neutral-7) 0%, rgba(242, 248, 250, 0) 100%);

  .container {
    position: relative;
    padding-top: var(--space-16);
    padding-bottom: var(--space-12);
    overflow: scroll;

    @include until-phone {
      padding-top: var(--space-12);
      padding-bottom: var(--space-10);
    }

    .title {
      @include header-3;
      font-weight: var(--font-weight-extra-bold);
      margin-bottom: var(--space-6);
      color: var(--text-color-grey-1);

      @include until-phone {
        margin-bottom: var(--space-4);
      }
    }

    .slider {
      :global(.slick-slide) {
        padding-left: var(--space-4) !important;

        &:first-child {
          padding-left: var(--page-side-space) !important;
        }
        &:last-child {
          padding-right: var(--page-side-space) !important;
        }
      }
    }

    .reviews {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: var(--space-12);

      @include until-phone {
        display: block;
        margin: 0 calc(var(--page-side-space) * -1);
      }
    }
  }
}
