@import 'scss/media-queries';

.row {
  display: grid;
  // https://stackoverflow.com/a/61240964
  grid-template-columns: repeat(var(--items-per-row), minmax(0, 1fr));
  grid-gap: var(--space-4);
  list-style-type: none;

  &.column-1 {
    --items-per-row: 1;
  }

  &.column-2 {
    --items-per-row: 2;
  }

  &.column-3 {
    --items-per-row: 3;
  }

  &.column-4 {
    --items-per-row: 4;
  }

  &.hidden-for-seo {
    max-height: 0;
    margin-top: 0;
    padding: 0;
    overflow: hidden;
  }
}

.cta {
  width: 100%;
  text-align: center;
  margin-top: var(--space-5);
}
