@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';
@import 'scss/spacing';

.popularContainer {
  position: relative;
  display: flex;
  border: 2px solid var(--neutral-5);
  border-radius: var(--border-radius-32);
  padding: var(--space-12);

  @include until-tablet {
    flex-direction: column;
    border: unset;
    border-radius: unset;
    padding: 0;
  }

  .title {
    @include col-3;
    @include header-3;
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: var(--space-6);
    color: var(--text-color-grey-1);

    @include until-tablet {
      @include col-12;
      margin-bottom: var(--space-4);
      padding: 0;
      max-width: 100%;
    }
  }

  .fireIcon {
    @include size(48px);
    display: block;
    margin-bottom: var(--space-4);

    @include until-phone {
      @include size(40px);

      margin-bottom: var(--space-2);
    }
  }

  .body {
    @include col-9;
    margin-left: var(--space-4);

    @include until-phone {
      @include col-12;
    }
    @include until-tablet {
      padding: 0;
      margin-left: 0;
      max-width: 100%;
    }

    h5 {
      @include header-5;
      color: var(--text-color-grey-1);
      margin-bottom: var(--space-3);

      @include until-phone {
        @include header-4;
      }

      &:not(:first-child) {
        margin-top: var(--space-4);

        @include until-phone {
          margin-top: var(--space-6);
        }
      }
    }
  }

  .chipsContainer {
    width: 100%;
    position: 'relative';
  }
}

.chipHeader {
  &:not(:last-child) {
    margin-bottom: var(--space-4);

    @include until-phone {
      margin-bottom: var(--space-6);
    }
  }
}
