@import 'scss/mixins';

.ratingContainer {
  display: flex;
  align-items: center;
  color: var(--secondary-variant-1);
}

.ratingValue {
  position: relative;
  top: 1px;
  margin-right: var(--space-2);
}

.rating {
  --star-width: 16px;

  &.sm {
    --star-width: 12px;
  }

  &.md {
    --star-width: 24px;
  }

  &.lg {
    --star-width: 40px;
  }

  &.isReadOnly {
    position: relative;
    width: calc(var(--star-width) * 5.5);
    height: var(--star-width);
  }

  &.isReadOnly,
  &.isReadOnly {
    &::before,
    &::after {
      position: absolute;
      @include size(100%);
      content: '';
      display: block;
    }

    &.isCompact {
      width: var(--star-width);

      &::before {
        width: 0;
      }
      &::after {
        width: var(--star-width);
      }
    }
  }

  &.isReadOnly::before {
    width: 100%;
    background: url('/rating-stars-empty.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.isReadOnly::after {
    z-index: 1;
    width: calc(var(--value, 2.5) / 5 * 100%);
    background: url('/rating-stars-filled.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.rating.selectable {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: var(--space-2);
  }

  .star {
    position: relative;
  }

  .star:hover svg {
    transform: scale(1.1);
    cursor: pointer;
  }

  .star input:checked + .starLabel svg:not([data-star]) path,
  .star:hover svg:not([data-star]) path,
  .star:hover ~ .star svg:not([data-star]) path {
    fill: var(--secondary-variant-1);
  }

  .starLabel svg:not([data-star]) {
    width: var(--star-width);
    height: var(--star-width);
    position: relative;
    vertical-align: middle;
    z-index: 1;
  }

  [data-star] {
    position: absolute;
    left: 50%;
    top: 50%;
    will-change: transform, scale;
    opacity: 0;
  }

  // celebrate star animation on giving 5 stars
  .star input:checked + .starLabel [data-star] {
    &[data-star='1'] {
      --x: -20px;
      --y: -20px;
      color: var(--error);
    }
    &[data-star='2'] {
      --x: -20px;
      --y: 16px;
      color: var(--primary);
    }
    &[data-star='3'] {
      --x: 20px;
      --y: -22px;
      color: var(--secondary-variant-1);
    }
    &[data-star='4'] {
      --x: 20px;
      --y: 10px;
      color: var(--success);
    }
    &[data-star='5'] {
      --x: 8px;
      --y: 20px;
      color: var(--secondary);
    }
    animation: showStar 1s cubic-bezier(0, 1, 0.88, 1);
  }

  .star input:checked + .starLabel svg {
    animation: bounceStar 0.1s;
  }

  @keyframes showStar {
    to {
      transform: translate(var(--x), var(--y)) scale(2.2);
      opacity: 1;
    }
  }

  @keyframes bounceStar {
    to {
      transform: scale(1);
    }
  }
}
